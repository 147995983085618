import React, { useState } from 'react';
import { PageProps, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Grid from '@material-ui/core/Grid';

import { Shell } from '@components/Shell/Shell';
import { useWhichLanguage } from '@src/hooks/useWhichLanguage';
import { Heading } from '@src/components/Heading/Heading';
import { SEO } from '@src/components/SEO/SEO';
import { PaintingExtended } from '@src/types/paintings';
import { Content } from '@src/components/Content/Content';
import { Gallery } from '@src/components/Gallery/Gallery';
import { MediaModal } from '@src/components/MediaModal/MediaModal';
import { MediaModalImage } from '@src/types/media';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GalleryItemSuperType } from '@src/types/gallery';

const IndexPage: React.FC<
  PageProps & {
    data: {
      api: {
        home: {
          id: number;
          enTitle: string;
          svTitle: string;
          enText: string;
          svText: string;
          enHtmlTitle: string;
          svHtmlTitle: string;
          enHtmlDescription: string;
          svHtmlDescription: string;
        } | null;
        paintingsPublished: PaintingExtended[] | null;
      };
      site: {
        siteMetadata: {
          siteUrl: string;
        };
      };
    };
  }
> = ({
  data: {
    api: { home, paintingsPublished: paintings },
    site: {
      siteMetadata: { siteUrl },
    },
  },
  location,
}) => {
  const { t } = useI18next();
  const { isEnglish } = useWhichLanguage();
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openMediaModal = () => {
    setIsMediaModalOpen(true);
  };

  const closeMediaModal = () => {
    setIsMediaModalOpen(false);
  };

  if (!home || !paintings) {
    return null;
  }

  const {
    enTitle,
    svTitle,
    enHtmlTitle,
    svHtmlTitle,
    enHtmlDescription,
    svHtmlDescription,
  } = home;

  const [firstPainting] = paintings;
  const modalImages = paintings.map((painting): MediaModalImage => {
    const image = getImage(
      painting.largeImagePathSharp?.childImageSharp || null
    );
    return {
      path: painting.imagePath,
      imageElement: image ? (
        <GatsbyImage image={image} objectFit="contain" alt={painting.title} />
      ) : (
        <></>
      ),
      title: painting.title,
      width: painting.width,
      height: painting.height,
      sold: painting.sold,
      paintingSlug: painting.slug,
    };
  });

  const galleryItems = paintings.map((painting): GalleryItemSuperType => {
    const { id, thumbnailImagePathSharp, title, width, height } = painting;
    return {
      id,
      title,
      width,
      height,
      thumbnailImagePathSharp: thumbnailImagePathSharp,
      pixelWidth: thumbnailImagePathSharp?.childImageSharp.gatsbyImageData
        .width as number,
      paintingSlug: painting.slug,
    };
  });

  return (
    <Shell>
      <SEO
        locationOrigin={siteUrl}
        locationPathname={location.pathname}
        openGraphImagePath={
          firstPainting?.largeImagePathSharp?.childImageSharp.gatsbyImageData
            .images.fallback?.src
        }
        openGraphImageWidth={
          firstPainting?.largeImagePathSharp?.childImageSharp.gatsbyImageData
            .width
        }
        openGraphImageHeight={
          firstPainting?.largeImagePathSharp?.childImageSharp.gatsbyImageData
            .height
        }
        title={isEnglish ? enHtmlTitle : svHtmlTitle}
        description={isEnglish ? enHtmlDescription : svHtmlDescription}
      />
      <Heading isHidden>{isEnglish ? enTitle : svTitle}</Heading>
      <Content>
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={11} sm={11} md={11} lg={10}>
            <Gallery
              onGalleryItemClick={(index) => {
                setCurrentImageIndex(index);
                openMediaModal();
              }}
              items={galleryItems}
            />
          </Grid>
        </Grid>
        <MediaModal
          mediaItems={modalImages}
          currentIndex={currentImageIndex}
          setCurrentIndex={setCurrentImageIndex}
          closeModal={closeMediaModal}
          isOpen={isMediaModalOpen}
        />
      </Content>
    </Shell>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    api {
      home {
        id
        enTitle
        svTitle
        enText
        svText
        enHtmlTitle
        svHtmlTitle
        enHtmlDescription
        svHtmlDescription
      }
      paintingsPublished {
        slug
        id
        title
        itemOrder
        height
        width
        imagePath
        sold
        thumbnailImagePathSharp: imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              height: 210
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        largeImagePathSharp: imagePathSharp {
          childImageSharp {
            gatsbyImageData(
              height: 1000
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;
